<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.nomenclador')} / ${$t('menu.flights')} / ${$t('lbl.airports')}` }}</v-card-title>
        <v-card-text><AirportList></AirportList></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import AirportList from './form/AirportList.vue'

export default {
  components: {
    AirportList,
  },
}
</script>
